// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-product-page-template-index-jsx": () => import("./../../../src/components/product-page-template/index.jsx" /* webpackChunkName: "component---src-components-product-page-template-index-jsx" */),
  "component---src-components-products-grid-template-index-jsx": () => import("./../../../src/components/products-grid-template/index.jsx" /* webpackChunkName: "component---src-components-products-grid-template-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

