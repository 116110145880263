// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Actions
// ----------------------------------------------------------------------------
export const INCREASE = 'INCREASE'
export const DECREASE = 'DECREASE'
export const UPDATE_HEADER_STATE = 'UPDATE_HEADER_STATE'
export const PATCH_HEADER_STATE = 'PATCH_HEADER_STATE'
export const UPDATE_WEBSITE_THEME = 'UPDATE_WEBSITE_THEME'
export const UPDATE_MEDIA_STATE = 'UPDATE_MEDIA_STATE'
export const UPDATE_SCREEN_SIZE_STATE = 'UPDATE_SCREEN_SIZE_STATE'

export const UPDATE_LIGHT_OR_DARK_MODE = 'UPDATE_LIGHT_OR_DARK_MODE'

// Message bus
export const UPDATE_NOTICE_QUEUE = 'UPDATE_NOTICE_QUEUE'
export const PUSH_NOTICE = 'PUSH_NOTICE'
export const POP_NOTICE = 'POP_NOTICE'

// Shopping cart
export const UPDATE_SHOPPING_CART = 'UPDATE_SHOPPING_CART'
export const RESET_SHOPPING_CART = 'RESET_SHOPPING_CART'
export const PUSH_OBJECT_INTO_SHOPPING_CART = 'PUSH_OBJECT_INTO_SHOPPING_CART'
export const POP_OBJECT_FROM_SHOPPING_CART = 'POP_OBJECT_FROM_SHOPPING_CART'
export const INCREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART =
  'INCREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART'
export const DECREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART =
  'DECREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART'

// Payment gateway
export const SET_FORWARD_DATA = 'SET_FORWARD_DATA'
export const DELETE_FORWARD_DATA = 'DELETE_FORWARD_DATA'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------ Action creators
// ----------------------------------------------------------------------------
/** updateLightOrDarkMode */
export const updateLightOrDarkMode = (initObject) => ({
  type: UPDATE_LIGHT_OR_DARK_MODE,
  payload: initObject,
})

/** setForwardData */
export const setForwardData = (initObject) => ({
  type: SET_FORWARD_DATA,
  payload: initObject,
})

/** deleteForwardData */
export const deleteForwardData = (initObject) => ({
  type: DELETE_FORWARD_DATA,
  payload: initObject,
})

/** resetShoppingCart */
export const resetShoppingCart = (initObject) => ({
  type: RESET_SHOPPING_CART,
  payload: initObject,
  meta: {
    debounce: {
      time: 1800,
      leading: false,
      trailing: true,
    },
  },
})

/** pushObjectIntoShoppingCart */
export const pushObjectIntoShoppingCart = (initObject) => ({
  type: PUSH_OBJECT_INTO_SHOPPING_CART,
  payload: initObject,
})

/** popObjectFromShoppingCart */
export const popObjectFromShoppingCart = (initObject) => ({
  type: POP_OBJECT_FROM_SHOPPING_CART,
  payload: initObject,
})

/** increaseCountOfObjectInShoppingCart */
export const increaseCountOfObjectInShoppingCart = (initObject) => ({
  type: INCREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART,
  payload: initObject,
})

/** decreaseCountOfObjectInShoppingCart */
export const decreaseCountOfObjectInShoppingCart = (initObject) => ({
  type: DECREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART,
  payload: initObject,
})

/** updateNoticeQueue */
export const updateNoticeQueue = (initObject) => ({
  type: UPDATE_NOTICE_QUEUE,
  payload: initObject,
})

/** pushNotice */
export const pushNotice = (initObject) => ({
  type: PUSH_NOTICE,
  payload: initObject,
})

/** popNotice */
export const popNotice = (initObject) => ({
  type: POP_NOTICE,
  payload: initObject,
})

/** updateShoppingCart */
export const updateShoppingCart = (initObject) => ({
  type: UPDATE_SHOPPING_CART,
  payload: initObject,
})

/** updateHeaderState */
export const updateHeaderState = (initObject) => ({
  type: UPDATE_HEADER_STATE,
  payload: initObject,
})

/** patchHeaderState */
export const patchHeaderState = (initObject) => ({
  type: PATCH_HEADER_STATE,
  payload: initObject,
})

/** updateMediaState */
export const updateMediaState = (initObject) => ({
  type: UPDATE_MEDIA_STATE,
  payload: initObject,
})

/** updateScreenSizeState */
export const updateScreenSizeState = (initObject) => ({
  type: UPDATE_SCREEN_SIZE_STATE,
  payload: initObject,
})

/** updateWebsiteTheme */
export const updateWebsiteTheme = (initObject) => ({
  type: UPDATE_WEBSITE_THEME,
  payload: initObject,
})

/** Increase */
export const increase = (initObject) => ({
  type: INCREASE,
  payload: initObject,
})

/** Decrease */
export const decrease = (initObject) => ({
  type: DECREASE,
  payload: initObject,
})
