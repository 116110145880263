// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import filter from 'lodash/filter'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import pushObjectIntoShoppingCart from '@bodhi-project/components/lib/shopping-cart/reducers/push-object-into-shopping-cart'
import popObjectFromShoppingCart from '@bodhi-project/components/lib/shopping-cart/reducers/pop-object-from-shopping-cart'
import increaseCountOfObjectInShoppingCart from '@bodhi-project/components/lib/shopping-cart/reducers/increase-count-of-object-in-shopping-cart'
import decreaseCountOfObjectInShoppingCart from '@bodhi-project/components/lib/shopping-cart/reducers/decrease-count-of-object-in-shopping-cart'
import resetShoppingCart from '@bodhi-project/components/lib/shopping-cart/reducers/reset-shopping-cart'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import * as actions from './actions'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Reducers
// ----------------------------------------------------------------------------
/** @type {defaultState} Default application state */
const defaultState = {
  counter: 0,
  lightOrDarkMode: 'light',
  headerState: {
    active: false,
    current: 'Root',
    source: 'Root',
    homeArrow: false,
    backArrow: false,
    backTo: '/',
    root: 'Prisma Books',
  },
  mediaState: {
    screenWidth: 1440,
    screenHeight: 900,
    isExtraLargeDesktop: false,
    isLargeDesktop: true,
    isDesktop: false,
    isTablet: false,
    isMobile: false,
    isAsHighAsMyImac: false,
    isTwoThirdsAsHighAsMyImac: false,
    isHalfAsHighAsMyImac: false,
    isOneThirdAsHighAsMyImac: false,
    isFourthAsHighAsMyImac: false,
    isVeryShortWhenComparedToMyImac: false,
    isPortrait: false,
    isRetina: false,
    currentWidth: 'isDesktop',
    currentHeight: 'isTwoThirdsAsHighAsMyImac',
  },
  screenSizeState: {
    screenWidth: 1440,
    screenHeight: 900,
  },
  websiteTheme: 'light',
  shoppingCartState: {
    objectsList: [],
    objectsCounts: {},
    totalCountOfObjects: 0,
    totalPriceOfObjects: 0,
    thatTotalPriceOfObjects: 0,
    thisTotalPriceOfObjects: 0,
  },
  noticeQueueState: [],
  forwardData: {
    forward: false,
    customer_name: 'NA',
    customer_email: 'NA',
    customer_phone: 'NA',
    source: 'NA',
    purpose: 'NA',
    source_order_id: 'NA',
    payment_account_id: 'NA',
    amount: 'NA',
    currency: 'NA',
    return_url: 'NA',
  },
}
/**
    * rootReducer - Root reducer for this package.
    * Root reducer is not called directly, but is comubined and used by Redux to initialise and manage state.
    *
    * @example
      dispatch(someAction({...somePayload...}));
    *
    * @param {object} previousState - A simple object that contains previous state. Default value is an empty object.
    * @param {object} action - An action object of the nature { type: ACTION_KEYWORD, payload: payloadObject }
    * @version 0.0.1
    * @todo none
    */
const rootReducer = (previousState = defaultState, action) => {
  switch (action.type) {
    case actions.SET_FORWARD_DATA:
      return Object.assign({}, previousState, {
        forwardData: action.payload,
      })
    case actions.DELETE_FORWARD_DATA:
      return Object.assign({}, previousState, {
        forwardData: action.payload,
      })
    case actions.RESET_SHOPPING_CART:
      return resetShoppingCart(defaultState, previousState, action.payload)
    case actions.PUSH_OBJECT_INTO_SHOPPING_CART:
      return pushObjectIntoShoppingCart(
        defaultState,
        previousState,
        action.payload
      )
    case actions.POP_OBJECT_FROM_SHOPPING_CART:
      return popObjectFromShoppingCart(
        defaultState,
        previousState,
        action.payload
      )
    case actions.INCREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART:
      return increaseCountOfObjectInShoppingCart(
        defaultState,
        previousState,
        action.payload
      )
    case actions.DECREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART:
      return decreaseCountOfObjectInShoppingCart(
        defaultState,
        previousState,
        action.payload
      )
    case actions.PUSH_NOTICE:
      const newQueueWithPushedItem = [
        ...previousState.noticeQueueState,
        action.payload,
      ]
      return Object.assign({}, previousState, {
        noticeQueueState: newQueueWithPushedItem,
      })
    case actions.POP_NOTICE:
      const newQueueWithPoppedItem = filter(
        previousState.noticeQueueState,
        (item) =>
          item.message !== action.payload.message &&
          item.description !== action.payload.description
      )
      return Object.assign({}, previousState, {
        noticeQueueState: newQueueWithPoppedItem,
      })
    case actions.UPDATE_NOTICE_QUEUE:
      return Object.assign({}, previousState, {
        noticeQueueState: action.payload,
      })
    case actions.UPDATE_SHOPPING_CART:
      return Object.assign({}, previousState, {
        shoppingCartState: action.payload,
      })
    case actions.INCREASE:
      return Object.assign({}, previousState, {
        counter: action.payload,
      })
    case actions.DECREASE:
      return Object.assign({}, previousState, {
        counter: action.payload,
      })
    case actions.PATCH_HEADER_STATE:
      return Object.assign({}, previousState, {
        headerState: action.payload,
      })
    case actions.UPDATE_HEADER_STATE: {
      const newHeaderState = Object.assign(
        {},
        { ...previousState.headerState },
        { ...action.payload }
      )
      return Object.assign({}, previousState, {
        headerState: newHeaderState,
      })
    }
    case actions.UPDATE_MEDIA_STATE:
      return Object.assign({}, previousState, {
        mediaState: action.payload,
      })
    case actions.UPDATE_SCREEN_SIZE_STATE:
      return Object.assign({}, previousState, {
        screenSizeState: action.payload,
      })
    case actions.UPDATE_WEBSITE_THEME:
      return Object.assign({}, previousState, {
        websiteTheme: action.payload,
      })
    case actions.UPDATE_LIGHT_OR_DARK_MODE:
      return Object.assign({}, previousState, {
        lightOrDarkMode: action.payload,
      })
    default:
      return previousState
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default rootReducer
